import {
  captureException,
  captureMessage,
  getCurrentScope,
} from '@sentry/react'
import { type AxiosResponse, isAxiosError } from 'axios'

import { HttpStatus } from '@/utils/constants/enums'

import {
  getMaskedPath,
  getResponseErrorMessage,
  IGNORED_RESPONSE_STATUS_CODES,
} from './utils'

const IGNORED_ERROR_MESSAGES: string[] = []

export function responseFulfilledInterceptor(response: AxiosResponse) {
  const transactionId = response.headers['x-transaction-id']

  if (typeof transactionId === 'string' && transactionId.length > 0) {
    getCurrentScope().setTag('transaction_id', transactionId)
  }

  return response
}

function getIsUserError(response: AxiosResponse | undefined) {
  if (!response) return false

  return (
    response.status < HttpStatus.ServerError &&
    response.status >= HttpStatus.BadRequest
  )
}

function getIsIgnoredErrorMessage(response: AxiosResponse) {
  const responseErrorMessage = getResponseErrorMessage(response)

  return IGNORED_ERROR_MESSAGES.some((message) =>
    new RegExp(`^${message}.*`, 'i').test(responseErrorMessage),
  )
}

function getResponseFingerprint(response: AxiosResponse) {
  return [
    String(response.config.method),
    getMaskedPath(response.config.url),
    String(response.status),
    String(getResponseErrorMessage(response.data)),
  ]
}

function getResponseErrorCode(response: AxiosResponse) {
  return response.data &&
    typeof response.data === 'object' &&
    'errorCode' in response.data
    ? response.data?.errorCode
    : null
}

export function responseErrorInterceptor(error: unknown) {
  if (!error) throw error

  if (isAxiosError(error)) {
    const transactionId = error.response?.headers['x-transaction-id']

    if (transactionId) {
      getCurrentScope().setTag('transaction_id', transactionId)
    }

    if (IGNORED_RESPONSE_STATUS_CODES.includes(error.response?.status ?? 0)) {
      throw new Error('Ignored API Error')
    }

    const response = error.response

    if (response) {
      error.name = 'API Error'

      const responseErrorMessage = getResponseErrorMessage(response)
      const isIgnoredErrorMessage = getIsIgnoredErrorMessage(response)

      if (isIgnoredErrorMessage) throw error

      const responseErrorCode = getResponseErrorCode(response)

      if (responseErrorMessage || responseErrorCode)
        error.message = responseErrorMessage || responseErrorCode
    }

    captureException(error, (scope) => {
      if (!response) return scope

      if (getIsUserError(response)) scope.setLevel('warning')

      scope.setFingerprint(getResponseFingerprint(response))

      try {
        scope.setContext('Request', {
          data: JSON.stringify(response.config.data, null, 2),
        })
        scope.setContext('Response', {
          data: JSON.stringify(response.data, null, 2),
          error: {
            code: getResponseErrorCode(response),
            message: getResponseErrorMessage(response),
          },
          status: response.status,
        })
      } catch {
        // ignore
      }

      return scope
    })
  } else if (error instanceof Error) {
    captureException(error)
  } else if (typeof error === 'string') {
    captureMessage(error)
  }

  if (error instanceof Error) {
    throw error
  } else {
    throw new TypeError('Unknown API Error')
  }
}
