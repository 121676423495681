import { StorageKey } from './constants/enums'
import queryClient from './queryClient'

/**
 * Clears local storage and tanstack query cache, but keeps the selected language
 */
export function clearAll() {
  try {
    const selectedLanguage = localStorage.getItem(StorageKey.Language)

    queryClient.clear()
    localStorage.clear()

    if (selectedLanguage) {
      localStorage.setItem(StorageKey.Language, selectedLanguage)
    }
  } catch {
    void 0
  }
}
