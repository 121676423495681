/* eslint-disable unicorn/no-typeof-undefined */

/**
 * Verify that the browser has all the required features to run the app.
 * @throws {Error}
 */
export function verifyEnvironment() {
  if (typeof globalThis.fetch === 'undefined') throw new Error('Missing fetch')
  if (typeof globalThis.Promise === 'undefined')
    throw new Error('Missing Promise')
  if (typeof String.prototype.includes === 'undefined')
    throw new Error("Missing 'String.prototype.includes'")
  if (typeof Object.entries === 'undefined')
    throw new Error("Missing 'Object.entries'")
  if (typeof Object.fromEntries === 'undefined')
    throw new Error("Missing 'Object.fromEntries'")
  if ((globalThis.document as any).documentMode)
    throw new Error('Is Internet Explorer')
}
