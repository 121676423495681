import type { ChangeEvent } from 'react'

import { Button, Text } from '@ocho/aurora'
import { IconLogout } from '@tabler/icons-react'
import { useTranslation } from 'react-i18next'

import Dialog from '@/components/Dialog'
import Select from '@/components/Select'
import { Flex } from '@/components/system'

import type { PartnerLocation } from '@/utils/api/partner/types'

type Props = {
  changeHandler: (event: ChangeEvent<HTMLSelectElement>) => void
  data: PartnerLocation[]
  handleSetLocation: () => void
  handleSignOut: () => void
  indexSelected: string
}

function SelectStoreDialog({
  changeHandler,
  data,
  handleSetLocation,
  handleSignOut,
  indexSelected,
}: Props) {
  const { t } = useTranslation()

  return (
    <Dialog
      footerActions={
        data.length ? (
          <Button
            onClick={handleSetLocation}
            $variant="primary"
            disabled={!indexSelected}
          >
            {t('continue')}
          </Button>
        ) : (
          <Button onClick={handleSignOut} type="button">
            <IconLogout />
            {t('signOut')}
          </Button>
        )
      }
      title={
        data.length ? t('selectLocation.header') : t('selectLocation.error')
      }
      dismissible={false}
      narrow
    >
      {data.length ? (
        <Flex flexDirection="column" gap="var(--space--medium)">
          <Text>{t('selectLocation.description')}</Text>
          <Select
            onChange={changeHandler}
            options={data.map((location, index) => ({
              label: `${location.name} - ${location.street1}`,
              value: index.toString(),
            }))}
            name="store"
            value={indexSelected}
          />
        </Flex>
      ) : (
        <Text>{t('contactAdmin')}</Text>
      )}
    </Dialog>
  )
}

export default SelectStoreDialog
