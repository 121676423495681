import { useEffect } from 'react'

import * as Sentry from '@sentry/react'
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom'

import { isDevelop } from '@/utils/constants/env'

const DEVELOPMENT_TRACE_SAMPLE = 1
const PRODUCTION_TRACE_SAMPLE = 0.3

const { VITE_API_HOST, VITE_SENTRY_DSN } = import.meta.env

Sentry.init({
  _experiments: {
    profilesSampleRate: 0.4,
  },
  autoSessionTracking: true,
  beforeSend(event, hint) {
    if (isDevelop) {
      // eslint-disable-next-line no-console
      console.dir(hint.originalException || hint.syntheticException)
      return Promise.resolve(null)
    }

    return event
  },
  dsn: VITE_SENTRY_DSN,
  environment: import.meta.env.VITE_ENV,
  ignoreErrors: [
    'Failed to fetch dynamically imported module',

    // Ignore postal code non serviceable errors
    'postalCode is not serviceable',

    'status code 429',
    // Safari web extension
    'safari-web-extension://',
    // Random plugins/extensions
    'top.GLOBALS',
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    'originalCreateNotification',
    'canvas.contentDocument',
    'MyApp_RemoveAllHighlights',
    'http://tt.epicplay.com',
    "Can't find variable: ZiteReader",
    'jigsaw is not defined',
    'ComboSearch is not defined',
    'http://loading.retry.widdit.com/',
    'atomicFindClose',
    // Facebook borked
    'fb_xd_fragment',
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
    'bmi_SafeAddOnload',
    'EBCallBackMessageReceived',
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    'conduitPage',
    // Generic error code from errors outside the security sandbox
    // You can delete this if using raven.js > 1.0, which ignores these automatically.
    'Script error.',
    // Avast extension error
    '_avast_submit',
  ],

  integrations: [
    Sentry.browserProfilingIntegration(),
    Sentry.browserTracingIntegration(),
    Sentry.reactRouterV6BrowserTracingIntegration({
      createRoutesFromChildren,
      matchRoutes,
      useEffect,
      useLocation,
      useNavigationType,
    }),
    Sentry.replayIntegration({
      blockAllMedia: true,
      maskAllInputs: true,
      maskAllText: false,
      networkCaptureBodies: true,
      networkDetailAllowUrls: [/^https:\/\/(\w|-)*\.?\.ocho\.co\/?.*$/g],
    }),
  ],

  profilesSampleRate: isDevelop
    ? DEVELOPMENT_TRACE_SAMPLE
    : PRODUCTION_TRACE_SAMPLE,

  replaysOnErrorSampleRate: 1,

  replaysSessionSampleRate: 0,

  tracePropagationTargets: [/^\//, 'localhost', new RegExp(VITE_API_HOST)],
  tracesSampleRate: isDevelop
    ? DEVELOPMENT_TRACE_SAMPLE
    : PRODUCTION_TRACE_SAMPLE,
})
