import type { PropsWithChildren } from 'react'
import { lazy, Suspense } from 'react'

import { ToastProvider } from '@radix-ui/react-toast'
import { PostHogProvider } from 'posthog-js/react'

import AppSkeleton from '@/modules/Layout/Skeleton'

import { useAlert } from '@/utils/store/alert'
import { useConfirmationAlert } from '@/utils/store/confirmationAlert'
import { usePinnedNotes } from '@/utils/store/pinnedNotes'
import { useToast } from '@/utils/store/toast'

const Alert = lazy(() => import('@/components/Alert'))
const Toast = lazy(() => import('@/components/Toast'))
const PinnedNotes = lazy(() => import('@/components/PinnedNotes'))
const ConfirmationAlert = lazy(() => import('@/components/ConfirmationAlert'))

const posthogApiKey = import.meta.env.VITE_PUBLIC_POSTHOG_KEY
const posthogOptions = Object.freeze({
  api_host: import.meta.env.VITE_PUBLIC_POSTHOG_HOST,
})

/**
 * The DefaultProvider is a wrapper for the entire application.
 * It provides: Banners and Alerts
 */
export function DefaultProvider({ children }: PropsWithChildren) {
  const { dismissNote, isShowing, notes } = usePinnedNotes()

  const [currentAlert, dismissAlert] = useAlert()
  const [currentBanner, dismissBanner] = useToast()
  const [confirmationAlert, dismissConfirmationAlert] = useConfirmationAlert()

  return (
    <PostHogProvider apiKey={posthogApiKey} options={posthogOptions}>
      <ToastProvider>
        {/* Main app content */}
        <Suspense fallback={<AppSkeleton />}>{children}</Suspense>

        {/* Pinned notes */}
        <Suspense fallback={null}>
          {isShowing ? (
            <PinnedNotes dismissNote={dismissNote} notes={notes} />
          ) : null}
        </Suspense>
        {/* Alerts */}
        <Suspense fallback={null}>
          {Boolean(currentAlert) && (
            <Alert {...currentAlert} onClose={dismissAlert}>
              {currentAlert?.message ?? currentAlert?.description}
            </Alert>
          )}
        </Suspense>
        {/* Confirmation alerts */}
        <Suspense fallback={null}>
          {Boolean(confirmationAlert) && (
            <ConfirmationAlert
              onClose={dismissConfirmationAlert}
              onConfirm={confirmationAlert?.onConfirm}
              description={confirmationAlert?.description}
              keyToConfirm={confirmationAlert?.keyToConfirm}
              title={confirmationAlert?.title}
            >
              {confirmationAlert?.children}
            </ConfirmationAlert>
          )}
        </Suspense>
        {/*
          Toast component manages to render null if
          there are no values present, so we don't need
          to check for currentBanner.
        */}
        <Suspense fallback={null}>
          <Toast
            onClose={dismissBanner}
            description={currentBanner?.description}
            duration={currentBanner?.duration}
            open={Boolean(currentBanner)}
            title={currentBanner?.title}
            type={currentBanner?.type}
          />
        </Suspense>
      </ToastProvider>
    </PostHogProvider>
  )
}
