import type { StorageKey } from './constants/enums'

export function isValid() {
  return (
    // eslint-disable-next-line unicorn/no-typeof-undefined
    typeof globalThis.localStorage.setItem !== 'undefined' &&
    typeof globalThis.localStorage.setItem === 'function'
  )
}

export function get(key: StorageKey) {
  try {
    return localStorage.getItem(key)
  } catch {
    void 0
  }
}

export function set(key: StorageKey, value: string) {
  try {
    localStorage.setItem(key, value)
  } catch {
    void 0
  }
}

export function remove(key: StorageKey) {
  try {
    localStorage.removeItem(key)
  } catch {
    void 0
  }
}
