import { useState } from 'react'

import { General } from '@/utils/errors'

const TIMEOUT = 2000

/**
 * useClipboard hook
 * inspired by {@link https://github.com/mantinedev/mantine/blob/master/src/mantine-hooks/src/use-clipboard/use-clipboard.ts}
 * @param { number } timeout
 */
function useClipboard({ timeout = TIMEOUT } = {}) {
  const [copyTimeout, setCopyTimeout] = useState<number | undefined>()
  const [state, setState] = useState({
    copied: false,
    error: null as Error | null,
  })

  const handleCopyResult = (value: boolean) => {
    clearTimeout(copyTimeout)

    setCopyTimeout(
      setTimeout(() => {
        setState((previousState) => ({ ...previousState, copied: false }))
      }, timeout) as unknown as number,
    )

    setState((previousState) => ({ ...previousState, copied: value }))
  }

  const copy = (valueToCopy: any, allowToCopy: boolean) => {
    if ('clipboard' in navigator && allowToCopy) {
      navigator.clipboard
        .writeText(valueToCopy)
        .then(() => {
          handleCopyResult(true)
        })
        .catch((error) => {
          setState((previousState) => ({ ...previousState, error }))
        })
    } else {
      setState({ copied: false, error: new Error(General.clipboard) })
    }
  }

  const reset = () => {
    setState({ copied: false, error: null })
    clearTimeout(copyTimeout)
  }

  return { copy, reset, state }
}

export default useClipboard
