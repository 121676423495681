import { create } from 'zustand'
import { combine } from 'zustand/middleware'
import { useShallow } from 'zustand/react/shallow'

import * as storage from '@/utils/storage'

import { StorageKey } from '../constants/enums'

export type ColorScheme = 'dark' | 'default'

function getInitialColorScheme(): ColorScheme {
  const systemPreference = globalThis.matchMedia('(prefers-color-scheme: dark)')
    .matches
    ? 'dark'
    : 'default'

  const localColorScheme = storage.get(StorageKey.ColorScheme) as
    | ColorScheme
    | undefined

  return localColorScheme ?? systemPreference
}

export const useThemeStore = create(
  combine(
    {
      colorScheme: getInitialColorScheme(),
    },
    (set) => ({
      toggleColorScheme: () => {
        set((state) => ({
          colorScheme: state.colorScheme === 'dark' ? 'default' : 'dark',
        }))
      },
    }),
  ),
)

export function toggleColorScheme() {
  useThemeStore.getState().toggleColorScheme()
}

export function useColorScheme() {
  return useThemeStore(useShallow((state) => state.colorScheme))
}

export function useTheme() {
  return useThemeStore(useShallow((state) => state))
}
