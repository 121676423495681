import styled, { createGlobalStyle } from 'styled-components'

import type { ColorScheme } from './utils/store/theme'

const GlobalStyles = createGlobalStyle<{ $theme?: ColorScheme }>`
  :root {
    --color--ocho: #7664ef;
    --color--blanco: #fff;
    --color--background__solid: #fff;
    ${({ $theme }) => {
      if ($theme === 'dark') {
        return `
          --color--background__solid: hsl(210, 22%, 7%);
          --color--blanco: hsl(210, 22%, 7%);
`
      }
    }}
    /* Dialog colors */
    --colors--dialog_shadow__1: hsla(210 22% 7% / 0.35);
    --colors--dialog_shadow__2: hsla(210 22% 7% / 0.2);
    --colors--dialog_shadow__dark: hsla(0 0% 0% / 0.5);
    --colors--sentinel_shadow: hsla(240 11% 62% / 0.55);

    /* Shadow colors */
    --colors--content__shadow: hsla(210 7% 56% / 0.7);

    /* Shadows */
    --dialog--box--shadow: var(--colors--dialog_shadow__1) 0 10px 38px -10px,
      var(--colors--dialog_shadow__2) 0 10px 20px -15px;
    --card--box--shadow: 0 0 3px var(--colors--content__shadow);

    /* Font Meta label size */
    --font_size--meta-xsmall: 10px;
    --font_size--meta-small: 12px;


    /* Common sizes */
    --size--small: 600px;
    --size--medium: 800px;
    --size--large: 900px;
    --size--xlarge: 1200px;

    /* Extra spaces */
    --size--accordion-trigger_height: 45px;
    --size--alert: var(--size--small);
    --size--button: 35px;
    --size--command-dialog: var(--size--medium);
    --size--detail_actions: 80px;
    --size--dialog-narrow: var(--size--small);
    --size--dialog: var(--size--large);
    --size--drawer-default: var(--size--small);
    --size--drawer-large: var(--size--large);
    --size--drawer-xlarge: var(--size--xlarge);
    --size--partner-bank-table__width: var(--size--medium);
    --size--partner__personal-data__container--width: var(--size--medium);
    --size--pdf_preview--width: 622px;
    --size--popover: var(--size--small);
    --size--quote--container: 500px;
    --size--toast: 450px;
    --size--tooltip-content: 350px;
    --size--truncated-text: 240px;

    /* Opacity */
    --opacity__xlight: 0.5;
    --opacity__light: 0.7;
    --opacity__medium: 0.85;
    --opacity__dark: 0.95;

    /* GRID templates */
    --grid--default_columns: repeat(auto-fill, minmax(300px, 1fr));
    --grid--small_columns: repeat(auto-fill, minmax(200px, 1fr));
    --grid--medium_columns: repeat(auto-fill, minmax(350px, 1fr));
  }

  hr {
    height: 1px;
    border: none;
    background-color: var(--color--background__neutral);
    margin: var(--space--large) 0;
  }

  textarea {
    color: var(--color--text__neutral);
    font-family: inherit;
    font-size: inherit;
    background-color: var(--color--background__neutral);
  }

  select {
    border: none;
    background-color: transparent;
  }

  button {
    border: none;
  }

  label {
    strong {
      display: block;
    }

    input,
    textarea {
      display: block;
      width: 100%;
    }
  }

  kbd {
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color--background-alt__neutral);
    border: 1px solid var(--color--background__neutral);
    border-radius: var(--space--xsmall);
    padding: 0 var(--space--xsmall);
    font-size: var(--font_size--small);
    color: var(--color--text-alt__neutral);
  }

  /* ===== Scrollbar CSS ===== */

  /* Firefox */
  * {
    scrollbar-width: auto;
    scrollbar-color: #8b7af1 var(--color--background__solid);
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 16px;
  }

  *::-webkit-scrollbar-track {
    background: var(--color--background__solid);
  }

  *::-webkit-scrollbar-thumb {
    background-color: #8b7af1;
    border-radius: 10px;
    border: 3px solid var(--color--background__solid);
  }

  .react-select {
    .react-select__single-value {
      display: inline-flex;
      align-items: center;
    }

    .react-select__control {
      border-color: var(--color--background__neutral);
      border-radius: var(--corner_radius--medium);

      &:hover {
        background-color: var(--color--background-alt__primary);
      }
    }

    .react-select__control--is-focused {
      box-shadow: 0 0 0 1px var(--color--solid-alt__primary);
      outline: none;
      border-color: var(--color--separator-alt__neutral);

      &:hover {
        border-color: var(--color--solid-alt__primary);
      }
    }

    .react-select__indicator-separator {
      display: none;
    }

    .react-select__indicator {
      color: var(--color--solid-alt__primary);
    }

    .react-select__menu {
      border-radius: var(--corner_radius--medium);
      border-color: var(--color--separator-alt__neutral);
    }

    .react-select__option {
      display: flex;
      align-items: center;

      &:active {
        background-color: var(--color--interactive-alt__primary);
      }
    }

    .react-select__option--is-focused {
      background-color: var(--color--background__primary);
    }

    .react-select__option--is-selected {
      background-color: var(--color--solid__primary);
    }
  }

  @keyframes fade-in {
    from { opacity: 0; }
    to { opacity: 1; }
  }

  @keyframes slide-up-fade {
    from {
      opacity: 0;
      transform: translateY(2px);
    }

    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes slide-right-fade {
    from {
      opacity: 0;
      transform: translateX(-2px);
    }

    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes slide-down-fade {
    from {
      opacity: 0;
      transform: translateY(-2px);
    }

    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes slide-left-fade {
    from {
      opacity: 0;
      transform: translateX(2px);
    }

    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
`

export const TextReduced = styled.p`
  opacity: 0.7;
  font-size: var(--font_size--small);
`

export const Label = styled.p`
  opacity: 0.7;
  font-size: var(--font_size--small);
`

export const FixedLabel60 = styled(Label)`
  max-width: 60%;
`

export const HeadingGrid = styled.h3`
  font-weight: var(--font_weight--bold);
  display: flex;
  align-items: center;
  gap: var(--space--xsmall);
  color: var(--color--text-alt__neutral);
`

export const GridField = styled.div`
  grid-column: 1 / -1;
`

export const DelightedText = styled.p<{ $isLighted?: boolean }>`
  ${({ $isLighted }) =>
    $isLighted &&
    `
    opacity: 0.85;
    color: var(--color--text-alt__neutral);
   `}
`

export const OverflowedText = styled.p`
  max-width: 90px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin: auto;
`

export const ValueToCopy = styled.button.attrs({ type: 'button' })`
  font-size: var(--font_size--medium);
  padding: var(--space--xsmall);
  background-color: transparent;
  border: none;

  &:hover {
    background-color: var(--color--solid__primary);
    color: var(--color--blanco);
    border-radius: var(--corner_radius--small);
  }
`

export const VinNumber = styled(ValueToCopy)`
  padding: 0;
  text-align: start;
`

export const Error = styled.p`
  font-weight: var(--font_weight--bold);
  color: var(--color--solid__danger);
  padding: 0 var(--space--xsmall);
  font-size: var(--font_size--small);
  height: var(--font_size--small);
  line-height: 1;
`

export default GlobalStyles
