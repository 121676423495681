import type { ComponentProps, ReactNode } from 'react'

import { Portal, Provider, Root, Trigger } from '@radix-ui/react-tooltip'

import * as Styled from './styled'

type Props = {
  children: ReactNode
} & ComponentProps<typeof Styled.StyledContent>

function Content({ children, ...props }: Props) {
  return (
    <Portal>
      <Styled.StyledContent {...props}>{children}</Styled.StyledContent>
    </Portal>
  )
}

export type Side = 'bottom' | 'left' | 'right' | 'top'

export type TooltipProps = {
  children: ReactNode
  delayDuration?: number
  disabled?: boolean
  side?: Side
  trigger?: ReactNode
} & ComponentProps<typeof Provider>

export default function Tooltip({
  children,
  disabled,
  trigger,
  ...props
}: TooltipProps) {
  if (disabled) return trigger

  return (
    <Provider {...props}>
      <Root>
        {trigger ? <Trigger asChild>{trigger}</Trigger> : null}
        <Content {...props}>
          {children}
          <Styled.Arrow />
        </Content>
      </Root>
    </Provider>
  )
}

export { Provider, Root, Trigger } from '@radix-ui/react-tooltip'
