import { create } from 'zustand'
import { useShallow } from 'zustand/react/shallow'

import type { Note } from '@/utils/api/note/types'

type PinnedNotesState = {
  hidePinnedNotes: () => void
  isShowing: boolean
  notes: Note[]
  setNotes: (notes: Note[]) => void
  showPinnedNotes: () => void
}

export const usePinnedNotesStore = create<PinnedNotesState>((set) => ({
  hidePinnedNotes: () => {
    set({ isShowing: false })
  },
  isShowing: false,
  notes: [],
  setNotes: (notes) => {
    set({ notes })
  },
  showPinnedNotes: () => {
    set({ isShowing: true })
  },
}))

export function usePinnedNotes() {
  return usePinnedNotesStore(
    useShallow((state) => ({
      dismissNote: state.hidePinnedNotes,
      isShowing: state.isShowing,
      notes: state.notes,
      setNotes: state.setNotes,
    })),
  )
}
