import type { ReactNode } from 'react'

import { create } from 'zustand'
import { combine } from 'zustand/middleware'
import { useShallow } from 'zustand/react/shallow'

/**
 * TODO: refactor Alert so it can behave as a confirmation alert
 */
export type ConfirmationAlertOptions = {
  children?: ReactNode
  description?: string
  keyToConfirm?: string
  onClose: () => void
  onConfirm?: () => void
  title?: string
}

export const useConfirmationAlertStore = create(
  combine({ current: null as ConfirmationAlertOptions | null }, (set) => ({
    dismiss() {
      set({ current: null })
    },
    show(current: ConfirmationAlertOptions) {
      set({ current })
    },
  })),
)

export function useConfirmationAlert() {
  return useConfirmationAlertStore(
    useShallow(({ current, dismiss }) => [current, dismiss] as const),
  )
}
