import type { AxiosError } from 'axios'

import {
  AccountNumber,
  Action,
  ApplicationId,
  ClipboardNotSupported,
  Comments,
  CustomerId,
  EmailAddress,
  EmailId,
  EmployeeId,
  LocationId,
  Number,
  OwnerId,
  PartnerId,
  PartnerName,
  PolicyNumber,
  ProductId,
} from './messages'

export const Missing = {
  accountNumber: AccountNumber,
  action: Action,
  applicationId: ApplicationId,
  comments: Comments,
  customerId: CustomerId,
  emailAddress: EmailAddress,
  emailId: EmailId,
  employeeId: EmployeeId,
  locationId: LocationId,
  ownerId: OwnerId,
  partnerId: PartnerId,
  partnerName: PartnerName,
  policyNumber: PolicyNumber,
  productId: ProductId,
}

export const TypeValues = {
  number: Number,
}

export const General = {
  clipboard: ClipboardNotSupported,
}

/**
 * method to get error message from error object
 * @param {Error} error
 * @param {string} defaultMessage
 * @returns {string}
 */
export function getErrorMessage(
  error: AxiosError | null | undefined,
  defaultMessage?: string | string[],
): string {
  let errorResponseMessage
  if (
    typeof error?.response?.data === 'object' &&
    error.response.data &&
    'errorMessage' in error.response.data
  ) {
    errorResponseMessage = error.response.data.errorMessage as string
  }

  let normalizedDefaultMessage: string | undefined
  if (Array.isArray(defaultMessage)) {
    normalizedDefaultMessage = defaultMessage.join(', ')
  } else {
    normalizedDefaultMessage = defaultMessage
  }

  const errorMessage =
    errorResponseMessage ?? error?.message ?? normalizedDefaultMessage

  if (!errorMessage) {
    return JSON.stringify(error?.response?.data)
  }

  return errorMessage
}
