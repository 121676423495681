import { useRef } from 'react'

import { TagType } from '@ocho/aurora'
import type { CleaveOptions } from 'cleave.js/options'
import * as yup from 'yup'

import {
  MAX_LICENSE_NUMBER_LENGTH,
  MIN_LICENSE_NUMBER_LENGTH,
  US_COUNTRY_CODE,
} from '@/utils/constants'
import { TagTypeSeverity } from '@/utils/constants/enums'
import { TypeValues } from '@/utils/errors'
import { UUID_REGEX } from '@/utils/validation/regex'

import type { FieldErrors } from './api/types'

const ELLIPSIS_CHARACTERS = 3
const DEFAULT_MAX_UUID_DIGITS = 8
const MAX_LENGTH = 9
const MAX_LENGTH_FOR_TRUNCATE = 15
const MAX_PHONE_LENGTH = 15

type Cases = {
  [key: string]: any
}

export const amountProps = {
  mask: {
    numeral: true,
    numeralThousandsGroupStyle: 'thousand',
  } as CleaveOptions,
  prefix: '$',
}

export function getIsUUID(uuid?: null | string) {
  if (!uuid) return false
  return UUID_REGEX.test(uuid)
}

export function getApplicationIdFromFieldErrors(
  fieldErrors: FieldErrors[] | undefined,
): null | string {
  const prefix = 'applicationId:'

  if (!fieldErrors?.length) return ''

  for (const error of fieldErrors) {
    if (error.message.includes(prefix)) {
      return error.message.split(prefix)[1]?.trim() ?? ''
    }
  }
  return null
}

export const isDevelopment =
  (typeof process !== 'undefined' &&
    import.meta.env.NODE_ENV === 'development') ||
  import.meta.env.DEV ||
  import.meta.env.VITE_VERCEL_ENV === 'preview'

export function getHolderName(customerData?: any): string {
  if (!customerData?.givenName) return ''

  const givenName = customerData.givenName || ''
  const middleName = customerData.middleName
    ? ` ${customerData.middleName}`
    : ''
  const familyName = customerData.familyName
    ? ` ${customerData.familyName}`
    : ''

  return `${givenName}${middleName}${familyName}`
}

export function getIdemPotencyKey(): string {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replaceAll(/[xy]/g, (c) => {
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    const r = Math.trunc(Math.random() * 16)
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    const v = c == 'x' ? r : (r & 0x3) | 0x8
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    return v.toString(16)
  })
}

export function getIdemPotencyKeyHeader() {
  return { 'Idempotency-Key': getIdemPotencyKey() } as const
}

export function useIdemKey(): string {
  return useRef(getIdemPotencyKey()).current
}

/**
 * function to clean the phone number
 * @param {string} phoneNumber
 * @param {string} prefix
 * @return {string}
 */
export function cleanPhoneNumber(phoneNumber: string, prefix?: string): string {
  // Remove letters and non-phone number related characters
  let clean = phoneNumber.match(/(\+|\d|;)*/g)?.join('') ?? ''
  // Keep only the first + sign
  if (clean.startsWith('+')) clean = `+${clean.replaceAll('+', '')}`
  // Max to 15 characters
  clean = clean.slice(0, Math.max(0, MAX_PHONE_LENGTH))
  // Only prefix in production
  if (!isDevelopment && prefix) {
    // NOTE: this can be improved to support international phone numbers
    clean = clean.replace(/^\+1/, '')
    return `${prefix}${clean}`
  }

  return clean
}

/**
 * function to remove the prefix
 * @param {string} phoneNumber
 * @returns {string}
 */
export function removePhoneNumberPrefix(phoneNumber: number | string): string {
  if (!phoneNumber) return ''

  return String(phoneNumber).replace(/^(\+1|1)/, '')
}

/**
 * Function to truncate a string with the maxLength and add ellipsis at the end.
 * @param {Object} params - The parameters object.
 * @param {string} params.text - The string to be truncated.
 * @param {number} params.maxLength - The maximum length of the string after truncation. Defaults to DEFAULT_MAX_LENGTH.
 * @return {string} - The truncated string with ellipsis at the end.
 */
export function truncateWithEllipsis({
  maxLength = MAX_LENGTH_FOR_TRUNCATE,
  text,
}: {
  maxLength?: number
  text: string
}): string {
  if (text.length <= maxLength) {
    return text
  }

  return `${text.slice(0, maxLength - ELLIPSIS_CHARACTERS)}...`
}

/**
 * Function to truncate a string and add in the middle the ellipsis.
 *
 * @param {Object} params - The parameters object.
 * @param {string} params.text - The string to be truncated.
 * @param {number} params.maxLength - The maximum length of the string after truncation. Defaults to DEFAULT_MAX_LENGTH.
 * @return {string} - The truncated string with ellipsis in the middle.
 */
export function truncateWithMiddleEllipsis({
  maxLength = DEFAULT_MAX_UUID_DIGITS,
  text = '',
}: {
  maxLength?: number
  text: string | undefined
}): string {
  const minLength = 2
  const uuidZeroRegex = /^0{8}-0{4}-0{4}-0{4}-0{12}$/
  const halfMaxLength = Math.floor(maxLength / minLength)

  if (!text) return ''

  if (text.length <= maxLength) {
    return text
  }

  if (uuidZeroRegex.test(text)) {
    return ''
  }

  const truncationLength = halfMaxLength - 1
  const firstPart = text.slice(0, truncationLength)
  const secondPart = text.slice(text.length - truncationLength)

  return `${firstPart}...${secondPart}`
}

/**
 * function to truncate a string
 * @param {string} text
 * @param {number} maxLength
 * @return {string}
 */
export function truncate(text: string, maxLength = DEFAULT_MAX_UUID_DIGITS) {
  if (text.length > maxLength) {
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    return text.slice(0, maxLength - 3) + '...'
  }

  return text
}
/**
 * executeIfFunction
 * method to execute if is a function, to avoid initialize cases values if is
 * not selected by the key
 * @param {function | any} f
 * @return {any}
 */
export function executeIfFunction(f: unknown) {
  if (typeof f === 'function') {
    return f()
  }
  return f
}

/**
 * switchHandler function
 * @param {string} key
 * @param {object<function> | any } cases
 * @param {any} defaultCase
 * @return {case[key] | defaultCase}
 */
export function switchHandler(
  key: keyof Cases | null | undefined,
  cases: Cases,
  defaultCase?: any,
) {
  if (!key) return defaultCase
  return executeIfFunction(cases[key]) ?? defaultCase
}

/**
 * sortByInclusionStatus
 * @param {Array<any>} arrayToBeSorted
 * @return {Array<any>}
 */
export function sortByInclusionStatus(arrayToBeSorted: any[]) {
  if (!arrayToBeSorted.length) return []
  const shadowArray = [...arrayToBeSorted]

  return shadowArray.sort((a, b) =>
    a.inclusionStatus?.localeCompare(b?.inclusionStatus),
  )
}

export function isEmpty(object: unknown) {
  if (!object) return true
  if (Object.keys(object).length === 0) return true
  if (Object.values(object).every((value) => !value)) return true
  return false
}

/**
 * method to check if a value
 * @param {any} value
 * @return {boolean}
 */
export function isNullOrUndefined(value: any) {
  return value === undefined || value === null
}

/**
 * method to parse strings to JSON
 * @param {string} stringToParse
 * @return {undefined | JSON}
 */
// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-parameters
export function parseJson<DataT extends object>(
  stringToParse: string,
): DataT | undefined {
  if (typeof stringToParse !== 'string') {
    return undefined
  }

  return JSON.parse(stringToParse)
}

/**
 * method to formatText using a pattern
 * @param {string} text
 * @param {string | RegExp} pattern
 * @param {string} replacement
 * @return {string | undefined}
 */
export function formatTextWithPattern(
  text: string,
  pattern: RegExp | string,
  replacement: string,
) {
  if (typeof text !== 'string') {
    return
  }

  return text.replaceAll(pattern, replacement)
}

/**
 * method to get the value from a key
 * @param {Object} obj
 * @param {string} key
 * @return {obj.value[key]}
 */
export function getValueByKey(object: any, key: string): any {
  if (isEmpty(object)) {
    return null
  }

  const indexOfs = Object.keys(object).indexOf(key)

  return Object.values(object)[indexOfs]
}

/**
 * method to concat string with commas
 * @param {Array.<string>} arrStrings
 * @return {string}
 */
export function formatStringWithCommas(arrayStrings: string[]): string {
  let formattedText = ''

  if (arrayStrings.length > 0) {
    arrayStrings.forEach((string, index) => {
      if (!string) return
      if (index >= 1) {
        formattedText += `, ${string}`
      } else {
        formattedText += string
      }
    })
  }

  return formattedText
}

/**
 * method to format number to a readable form
 * @param {string} number
 * @return {string}
 */
export function formatPhoneNumber(number?: string): string {
  if (typeof number !== 'string') {
    return ''
  }

  if (!number) return ''

  // remove country code
  const formattedNumber = number.replace(/(\+1)/, '')
  // return phone number with dashes
  return formattedNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
}

/**
 * method to format a number, string or bigint into USD currency
 * @param {bigint | number} value
 * @return {string}
 */
export function currencyFormatter(value: unknown): string {
  if (value === null || value === undefined) {
    return ''
  }

  // eslint-disable-next-line @typescript-eslint/no-base-to-string
  const number = Number(value.toString().replaceAll(',', ''))

  if (Number.isNaN(number)) {
    throw new TypeError(TypeValues.number)
  }

  return new Intl.NumberFormat('en-US', {
    currency: 'USD',
    style: 'currency',
  }).format(number)
}

/**
 * method to replace a string with a regex
 * @param {string} input
 * @param {Array<RegExp>} regexArray
 * @param {string} replacement
 * @returns {string}
 */
export function replaceWithRegex(
  input: string,
  regexArray: RegExp[],
  replacement = '',
): string {
  let result = input
  regexArray.forEach((regex: RegExp) => {
    result = result.replace(regex, replacement)
  })
  return result
}

/**
 * method to convert a string to a number
 * @param {string} value
 * @returns {number}
 */
export function convertToNumber(value: string): number {
  if (!value) {
    return 0
  }

  if (typeof value !== 'string') return value

  return Number(value.replace(',', ''))
}

/**
 * method to check an array of any if are numbers
 * @param {Array<unknown>} values
 * @returns {boolean}
 */
export function allAreNumber(...values: unknown[]): boolean {
  return values.every(
    (value) => typeof value === 'number' && !Number.isNaN(value),
  )
}

/**
 * method to capitalize the first letter of a string
 * @param string
 * @returns
 */
export function capitalizeFirstLetter(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

/**
 * function to validate if all the values received are a valid numbers,
 * even if the number is a string
 * @param {Array<number | string>} values
 * @returns {boolean}
 */
export function allAreValidNumbers(...values: (number | string)[]): boolean {
  return values.every((value) => {
    if (isNullOrUndefined(value) || value === '') return false

    if (typeof value === 'number') {
      return !Number.isNaN(value)
    }

    if (typeof value === 'string') {
      return !Number.isNaN(convertToNumber(value))
    }

    return false
  })
}

/**
 * Function that does nothing
 */
export const noop = () => void 0

// Find the index of the item in the array of non-sorted items
// because the sorting is usually for display purposes
export const getItemIndexFromCollection = (
  itemId: number | string,
  collection: ({ id: number | string } & object)[],
) => collection.findIndex((item) => item.id === itemId)

/**
 * Function to return maxLength+ when the length is greater than maxLength
 * default maxLength is 9
 * @param {array} array
 * @param {number} maxLength
 * @returns {string}
 */
export function getLengthOrMax(array: any[], maxLength = MAX_LENGTH) {
  if (array.length > maxLength) {
    return `${maxLength}+`
  }

  return array.length.toString()
}

export const requiredWhenLicense = ({ usOnly = true }) =>
  ({
    is: (licenseCountry?: null | string) =>
      Boolean(licenseCountry?.toString()) &&
      (licenseCountry !== 'OT' || !usOnly),
    otherwise: (schema: any) => schema.notRequired(),
    then: (schema: any) =>
      schema
        .required('errors.required')
        .max(MAX_LICENSE_NUMBER_LENGTH, 'errors.maxLength')
        .min(MIN_LICENSE_NUMBER_LENGTH, 'errors.minLength'),
  }) as const

export const requiredWhenUS = {
  is: (licenseCountry?: null | string) => licenseCountry === US_COUNTRY_CODE,
  otherwise: (schema: yup.StringSchema) => schema.notRequired(),
  then: (schema: yup.StringSchema) => schema.required('errors.required'),
} as const

/**
 * method to return the TagType severity for two statuses, receives as params the two objects for the statuses
 * and the two statuses to compare
 * @param {object} params
 * @param {object} params.statusTags1
 * @param {object} params.statusTags2
 * @param {string} params.status1Key
 * @param {string} params.status2Key
 * @returns {TagType}
 */
export function getCombineStatuses({
  status1Key,
  status2Key,
  statusTags1,
  statusTags2,
}: {
  status1Key?: string
  status2Key: string
  statusTags1: Record<string, TagType>
  statusTags2: Record<string, TagType>
}): TagType {
  // get tag types from status
  const tagType1 = status1Key ? statusTags1[status1Key] : TagType.Neutral
  const tagType2 = status2Key ? statusTags2[status2Key] : TagType.Neutral

  // get severity
  const severity1 = tagType1
    ? TagTypeSeverity[tagType1 as keyof typeof TagTypeSeverity]
    : 1
  const severity2 = tagType2
    ? TagTypeSeverity[tagType2 as keyof typeof TagTypeSeverity]
    : 1

  // return higher severity tag type
  if (Math.max(severity1, severity2) === severity1) {
    return tagType1 ?? TagType.Neutral
  }
  return tagType2 ?? TagType.Neutral
}

// FORMIK VALIDATIONS
export const formattedNumber = yup
  .number()
  .transform((_o, v) =>
    typeof v === 'string' ? Number.parseFloat(v.replaceAll(',', '')) : v,
  )

export const requiredFormattedNumber =
  formattedNumber.required('errors.required')

export const convertStringToBoolean = (value: string) => value === 'true'

export function delay(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

/**
 * Gets boolean value taking into account different formats
 */
export function getBool(value: any): boolean {
  if (value === 'true') return true
  if (value === 'false') return false
  if (value === '0') return false
  if (value === '1') return true
  return Boolean(value)
}
